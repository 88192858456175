<template>
  <div>
    <h1>Category</h1>
  </div>
</template>

<script>
export default {
  name: "Category",
  data: () => ({}),
  mounted() {},
};
</script>
